/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap');*/

@import url('https://fonts.googleapis.com/css2?family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/*@font-face {
  font-family: 'SBLHebrew';
  src:  url('../assets/fonts/SBL_Hbrw.ttf') format('truetype');
}*/

.small-caps {
    font-variant: small-caps;
}
